(function (n, i, v, r, s, c, x, z) {
  x = window.AwsRumClient = { q: [], n: n, i: i, v: v, r: r, c: c };
  window[n] = function (c, p) {
    x.q.push({ c: c, p: p });
  };
  z = document.createElement('script');
  z.async = true;
  z.src = s;
  document.head.insertBefore(z, document.head.getElementsByTagName('script')[0]);
})('cwr', '3e0856b3-4486-4790-a922-20f8f3310069', '1.0.0', 'eu-central-1', 'https://client.rum.us-east-1.amazonaws.com/1.0.5/cwr.js', {
  sessionSampleRate: 1,
  guestRoleArn: 'arn:aws:iam::828910150645:role/RUM-Monitor-eu-central-1-828910150645-4632198815461-Unauth',
  identityPoolId: 'eu-central-1:14fefe53-9d60-48ab-9923-919a12cb57e9',
  endpoint: 'https://dataplane.rum.eu-central-1.amazonaws.com',
  telemetries: ['performance', 'errors', 'http'],
  allowCookies: false,
  enableXRay: false,
});
